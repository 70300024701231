import React, { useState, useEffect } from "react";
import "./HeaderPc.css";
import logo from "../../assets/image/logo-header.webp";
import { NavLink, useLocation } from "react-router-dom";

const HeaderPc = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();

  const checkSessionExpiry = () => {
    const tokenExpiry = localStorage.getItem('tokenExpiry');
    const currentTime = new Date().getTime();

    if (tokenExpiry && currentTime > tokenExpiry) {
      localStorage.clear();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    checkSessionExpiry();
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div className="">
      <div className="header_Pc d-flex justify-content-center align-items-center">
        <nav
          className="nav_bar_Pc mt-2 d-flex justify-content-between align-items-center"
          style={{ width: "1500px" }}
        >
          <div className="text-start">
            <a href="/">
              <img
                src={logo}
                width="100%"
                alt="500AE Logo"
                className="header-logo"
              />
            </a>
          </div>

          <div className="dropdownheader">
            <NavLink className="nav_link_Pc dropdown-toggle" to="/">TRANG CHỦ</NavLink>
            <div className="dropdown-menu">
              
              {!isLoggedIn && location.pathname !== '/login' && (
                <NavLink
                  to="/login"
                  className="dropdown-item"
                >
                  ĐĂNG NHẬP
                </NavLink>
              )}
              {!isLoggedIn && location.pathname !== '/register' && (
                <NavLink
                  to="/register"
                  className="dropdown-item"
                >
                  ĐĂNG KÝ
                </NavLink>
              )}
              {isLoggedIn && (
                <NavLink to="/profile" className="dropdown-item">
                  TRANG CÁ NHÂN
                </NavLink>
              )}
            </div>
          </div>

          <div>
            <NavLink to="/soikeo" className="nav_link_Pc">
              LỊCH THI ĐẤU
            </NavLink>
          </div>
          <div>
            <NavLink to="/bxh" className="nav_link_Pc">
              BXH
            </NavLink>
          </div>
          <div>
            <NavLink
              to="https://www.youtube.com/@MeBong-500ae/"
              className="nav_link_Pc"
              target="_blank"
              rel="noopener noreferrer"
            >
              HIGHLIGHT
            </NavLink>
          </div>
          <div>
            <NavLink
              to="https://t.me/TIP500AE"
              target="_blank"
              rel="noopener noreferrer"
              className="nav_link_Pc"
            >
              KHUYẾN MÃI
            </NavLink>
          </div>

          <div className="dropdownheader">
            <span className="nav_link_Pc dropdown-toggle">TIN TỨC</span>
            <div className="dropdown-menu">
              <NavLink
                to="https://500ae.name"
                target="_blank"
                rel="noopener noreferrer"
                className="dropdown-item"
              >
                500AE THỂ THAO
              </NavLink>
              <NavLink
                to="https://500ae88.blog"
                target="_blank"
                rel="noopener noreferrer"
                className="dropdown-item"
              >
                500AE BÓNG ĐÁ
              </NavLink>
            </div>
          </div>

          <div>
            <NavLink
              to="https://shbete.life/?f=3699878"
              className="header_nhacaiuytin"
              target="_blank"
              rel="noopener noreferrer"
            >
              NHÀ CÁI UY TÍN
            </NavLink>
            <NavLink
              to="https://shbete.life/?f=3699878"
              className="header_cuocngay"
              target="_blank"
              rel="noopener noreferrer"
            >
              CƯỢC NGAY
            </NavLink>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default HeaderPc;
